import {Injectable} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Router} from "@angular/router";
import {UsersRepositoryService} from "@core/db/repositories/users.repository.service";
import {User} from "@core/db/models/users.model";
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private userRepositoryService: UsersRepositoryService
  ) {
  }

  signIn(email: string, password: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          this.afAuth.authState.subscribe(async (user: firebase.User | null) => {
            if (user) {
              const userData: User | null = await this.getUserData(user.email);

              if (userData) {
                localStorage.setItem('shiftster', JSON.stringify(userData));
                resolve(userData);
              }
              reject();
            }
          });
        })
        .catch((error) => {
          console.error('Error try to login ' + error)
          reject();
        });
    })
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('shiftster')!);
    return user !== null;
  }

  get user(): any {
    return JSON.parse(localStorage.getItem('shiftster')!);
  }

  async getUserData(email: string | null) {
    if (email) {
      const data: User | null = await this.userRepositoryService.getByEmail(email);

      return (data) ?
        ({
          id: data.id,
          name: data.name,
          lastname: data.lastname,
          fullName: `${data.name} ${data.lastname}`,
          email: data.email,
          profile: data.profile,
        } as User) : null;
    }
    return null;
  }

  signOut() {
    this.afAuth.signOut().then(async () => {
      localStorage.removeItem('shiftster');
      await this.router.navigate(['login']);
    });
  }
}
