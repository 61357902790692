import { Injectable } from '@angular/core';
import {FirestoreRepository} from "@core/db/repositories/firestore.repository";
import {Firestore, QueryFieldFilterConstraint, where} from "@angular/fire/firestore";
import {Collections} from "@core/db/collections";
import {isEmpty as _isEmpty, first as _first} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class UsersRepositoryService extends FirestoreRepository{
  constructor(db: Firestore) {
    super(db, Collections.USERS);
  }

  async getByEmail<User extends { id?: string; }>(email: string): Promise<User | null> {
    const filter: QueryFieldFilterConstraint = where('email', '==', email);

    try {
      const result = await this.find<User>(filter);
      return !_isEmpty(result) ? _first(result) as User : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
